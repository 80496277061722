
*,
*:before,
*:after{
padding: 0;
box-sizing: border-box;
}

.body{
    min-width: 100%;
}

.form{
margin-top: 50px;
display: flex;
flex-direction: column;
gap: 30px;
justify-content: center;
align-items: center;
padding: 20px;
min-height: 400px;
margin-top: 100px;
}

.form *{
font-family: 'Poppins',sans-serif;
color: #fdf9f2;
letter-spacing: 0.5px;
outline: none;
border: none;


}

.form input{
height: 50px;
width: 330px;
background-color:#fdf9f2;
border-radius: 3px;
padding: 0 10px;
font-size: 14px;
font-weight: 500;
color: #ff6900;
}
::placeholder{
color:  #ff6900;
font-weight: 300;
}
.form button{
width: 330px;
background-color:  #ff6900;
color: #ffffff;
padding: 15px 0;
font-size: 18px;
font-weight: 600;
border-radius: 5px;
cursor: pointer;
}



.image{
display: flex;
justify-content: center;
margin-top: -30px;
}

.image img{
border-radius: 50%;
box-shadow:rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.head{
    color: #B24000;
}



/* WAVE EFFECT */

.wave-sec {
    display: flex;
    flex-direction: column;
    position: relative;
}

@keyframes move_wave {
    0% {
        transform: translateX(0) translateZ(0) scaleY(1)
    }

    50% {
        transform: translateX(-25%) translateZ(0) scaleY(0.55)
    }

    100% {
        transform: translateX(-50%) translateZ(0) scaleY(1)
    }
}

.wave-flow {
    position: relative;
    height: 300px;
    margin-bottom: 24px;
}

.waveWrapper {
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
}

.waveWrapperInner {
    position: absolute;
    width: 100%;
    overflow: hidden;
    height: 100%;
    bottom: 0px;

}

.bgTop {
    z-index: 15;
    opacity: 1;
}

.bgMiddle {
    bottom: 10px;
    z-index: 10;
    opacity: 1;

}

.bgBottom {
    z-index: 5;
}

.wave {
    position: absolute;
    left: 0;
    width: 10000px;
    height: 100%;
    background-repeat: repeat no-repeat;
    background-position: 0 bottom;
    transform-origin: center bottom;
}

.waveTop {
    background-size: 50% 100px;
    bottom: 5px;
}

.waveAnimation .waveTop {
    animation: move_wave 15s linear infinite;
    -webkit-animation: move_wave 12s linear infinite;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.waveMiddle {
    background-size: 50% 180px;
    bottom: 27px;
}

.waveAnimation .waveMiddle {
    animation: move_wave 14s linear infinite;
}

.waveBottom {
    background-size: 50% 250px;
    bottom: 50px;
}

.waveAnimation .waveBottom {
    animation: move_wave 16s linear infinite;
}


.active{
    color:#d81616;
   }